import React, { useEffect } from 'react';
import { graphql } from 'gatsby';
import { useStore } from 'src/components/GlobalState';

import Layout from 'src/components/Layout';
import ArticleHeroModule from 'src/components/ArticleHeroModule';
import ColumnModule from 'src/components/ColumnModule';

import getComponentByType from 'src/utils/getComponentByType';

const ArticleTemplate = ({ data }) => {
  const {
    text,
    footerModules,
    isNavTransparent,
    videoUrl,
    autoplayVideo,
  } = data.article;
  const openModal = useStore((state) => state.openModal);

  useEffect(() => {
    if (autoplayVideo && typeof videoUrl === 'string') {
      openModal('video', videoUrl);
    }
  });

  return (
    <Layout hasFixedNav={true} isNavTransparent={isNavTransparent}>
      <ArticleHeroModule {...data.article} />
      {text && (
        <ColumnModule text={text} colorMode="light" alignment="Document" />
      )}
      {footerModules &&
        footerModules.map((footerModule) => {
          const { __typename } = footerModule;
          const Component = getComponentByType(__typename);
          return React.createElement(
            Component || 'div',
            { key: footerModule.id, ...footerModule },
            null
          );
        })}
    </Layout>
  );
};

export default ArticleTemplate;

export const pageQuery = graphql`
  query($slug: String!) {
    article: contentfulArticle(slug: { eq: $slug }) {
      isNavTransparent
      slug
      title
      title2
      subtitle
      videoUrl
      autoplayVideo
      stats
      statLabels
      text {
        json
      }
      brand {
        name
        identifier
        media {
          file {
            url
          }
        }
      }
      heroImage {
        fluid(maxWidth: 2000, quality: 90) {
          ...GatsbyContentfulFluid_withWebp
        }
      }
      footerModules {
        ... on ContentfulFeaturedArticlesModule {
          ...FeaturedArticlesModuleFragment
        }
        ... on ContentfulSliderModule {
          ...SliderModuleFragment
        }
        ... on ContentfulNewTwoUpModule {
          ...NewTwoUpModuleFragment
        }
        ... on ContentfulColumnModule {
          ...ColumnModuleFragment
        }
        ... on ContentfulGridModule {
          ...GridModuleFragment
        }
      }
    }
  }
`;
