import React from 'react';
import styled from 'styled-components';
import css from 'src/theme/css';

import { minWidth } from 'src/utils/breakpoints';
import globalTheme from 'src/theme';
import useMediaQuery from 'src/hooks/useMediaQuery';

import Stack, * as stack from 'src/components/Stack';
import Text from 'src/components/Text';

const StatBackground = styled(Stack)`
  background: ${globalTheme.color.white10};
  padding: 2rem 1rem;

  @media ${minWidth.s} {
    padding: 2rem;
  }

  @media ${minWidth.m} {
    padding: 2rem 3rem;
  }
`;

export default ({ title, label }) => {
  const isLarge = useMediaQuery(minWidth.s);

  return (
    <StatBackground
      alignment={stack.alignments.start}
      direction={isLarge ? 'column' : 'row'}
      spacing="1rem"
    >
      <Text variant="heading.m">{title}</Text>
      <hr
        css={css({
          display: ['none', 'block'],
          border: 'none',
          height: '1px',
          width: '100%',
          maxWidth: 6,
          bg: 'purpleLight',
        })}
      />
      <Text>{label}</Text>
    </StatBackground>
  );
};
