import React from 'react';
import styled from 'styled-components';
import zipWith from 'lodash/zipWith';
import css from 'src/theme/css';
import { minWidth } from 'src/utils/breakpoints';
import useMediaQuery from 'src/hooks/useMediaQuery';
import { useStore } from 'src/components/GlobalState';

import Text from 'src/components/Text';
import Icon from 'src/components/IconPreset';
import Stack, * as stack from 'src/components/Stack';
import SmartButton, * as smartButton from 'src/components/SmartButton';
import TextStack from 'src/components/TextStack';
import CircleIcon, * as circleIcon from 'src/components/CircleIcon';
import ModuleWrapper from 'src/components/ModuleWrapper';

import Stat from './Stat';

const ContentGrid = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  grid-gap: 2rem;
  align-items: center;
  justify-items: center;

  @media ${minWidth.s} {
    justify-items: start;
    grid-template-columns: 3fr 2fr;
    grid-template-rows: 1fr auto;
  }
`;

const StyledTextStack = styled(TextStack)`
  margin: auto 0;
`;

const StatStack = styled(Stack)`
  border-radius: 1rem;
  overflow: hidden;
  grid-column: 1 / span 2;
  justify-self: stretch;
`;

const StyledIcon = styled(Icon)`
  fill: white;
`;

const Button = styled.button`
  justify-self: center;

  transform: scale(1.4);

  :hover {
    ${circleIcon.hover}
  }
`;

export default ({
  heroImage,
  title,
  title2,
  subtitle,
  brand,
  videoUrl,
  stats,
  statLabels,
  ...other
}) => {
  const openModal = useStore((state) => state.openModal);
  const isSmall = useMediaQuery(minWidth.s);
  const isMedium = useMediaQuery(minWidth.m);

  const statProps = zipWith(stats, statLabels, (title, label) => ({
    title,
    label,
  }));

  return (
    <ModuleWrapper
      isHero={true}
      isViewportHeight={true}
      hasScrim={true}
      colorMode="dark"
      flexDirection="column"
      alignSelf="stretch"
      justifyContent={['center', 'start']}
      background={{ smallImage: heroImage, largeImage: heroImage }}
      {...other}
      css={css({
        px: 'pageMargin',
        py: 'sectionMargin',
        alignSelf: 'stretch',
        flexDirection: 'row',
      })}
    >
      <ContentGrid css={css({ color: 'mode', flex: '1 1 100%' })}>
        <StyledTextStack
          alignItems={['center', 'start']}
          textAlign={['center', 'start']}
          justifyContent={stack.justifications.center}
          size="m"
        >
          <StyledIcon naturalHeight {...brand} />
          <Text
            variant="heading.xxl"
            as="h1"
            css={css({ textIndent: '-0.04em' })}
          >
            {title}
          </Text>
          {title2 && (
            <Text
              variant="heading.xxl"
              as="h1"
              css={css({ textIndent: '-0.04em', marginTop: 0 })}
            >
              {title2}
            </Text>
          )}
          {subtitle && <Text as="p">{subtitle}</Text>}
          {videoUrl && !isMedium && (
            <SmartButton
              variant={smartButton.variants.play}
              action={smartButton.actions.videoModal}
              label="Watch the video"
              videoUrl={videoUrl}
            />
          )}
        </StyledTextStack>
        {videoUrl && isMedium && (
          <Button onClick={() => void openModal('video', videoUrl)}>
            <CircleIcon type="play" />
          </Button>
        )}
        {statProps.length > 0 && isSmall && (
          <StatStack
            direction={isSmall ? stack.directions.row : stack.directions.column}
            justification={stack.justifications.stretch}
            spacing="1px"
          >
            {statProps.map((props) => (
              <Stat {...props} />
            ))}
          </StatStack>
        )}
      </ContentGrid>
    </ModuleWrapper>
  );
};
